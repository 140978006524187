(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mobx"), require("React"), require("ReactDOM"), require("mobxReact"), require("sbMsgBusModule"), require("sbRespBlockLib"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sbPopupsHostLib", ["mobx", "React", "ReactDOM", "mobxReact", "sbMsgBusModule", "sbRespBlockLib", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sbPopupsHostLib"] = factory(require("mobx"), require("React"), require("ReactDOM"), require("mobxReact"), require("sbMsgBusModule"), require("sbRespBlockLib"), require("sbJsExtends"));
	else
		root["sbPopupsHostLib"] = factory(root["mobx"], root["React"], root["ReactDOM"], root["mobxReact"], root["sbMsgBusModule"], root["sbRespBlockLib"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 